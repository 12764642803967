import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/login',
  name: 'login',
  component: () => import( /* webpackChunkName: "login" */ '../views/login.vue')
},
{
  path: '/Home',
  name: 'Home',
  component: Home,
  children: [{
    path: '/menuRunList',
    name: 'menuRunList',
    component: () => import( /* webpackChunkName: "menuRunList" */ '../views/menuRun/menuRunList.vue')
  },
  {
    path: '/menuEdit',
    name: 'menuEdit',
    component: () => import( /* webpackChunkName: "menuEdit" */ '../views/menuRun/menuEdit.vue')
  },
  {
    path: '/standardGoods',
    name: 'standardGoods',
    component: () => import( /* webpackChunkName: "standardGoods" */ '../views/goods/standardGoods.vue')
  },
  {
    path: '/standardGoodsEdit',
    name: 'standardGoodsEdit',
    component: () => import( /* webpackChunkName: "standardGoodsEdit" */ '../views/goods/standardGoodsEdit.vue')
  },
  {
    path: '/goodsClassList',
    name: 'goodsClassList',
    component: () => import( /* webpackChunkName: "goodsClassList" */ '../views/goods/goodsClassList.vue')
  },
  {
    path: '/goodsClassEdit',
    name: 'goodsClassEdit',
    component: () => import( /* webpackChunkName: "goodsClassEdit" */ '../views/goods/goodsClassEdit.vue')
  },
  {
    path: '/packageList',
    name: 'packageList',
    component: () => import( /* webpackChunkName: "packageList" */ '../views/packge/packageList.vue')
  },
  {
    path: '/packageEdit',
    name: 'packageEdit',
    component: () => import( /* webpackChunkName: "packageEdit" */ '../views/packge/packageEdit.vue')
  },
  {
    path: '/batchChangePrice',
    name: 'batchChangePrice',
    component: () => import( /* webpackChunkName: "batchChangePrice" */ '../views/packge/batchChangePrice.vue')
  },
  {
    path: '/agentList',
    name: 'agentList',
    component: () => import( /* webpackChunkName: "agentList" */ '../views/agent/agentList.vue')
  },
  {
    path: '/agentEdit',
    name: 'agentEdit',
    component: () => import( /* webpackChunkName: "agentEdit" */ '../views/agent/agentEdit.vue')
  },
  {
    path: '/agentRoleList',
    name: 'agentRoleList',
    component: () => import( /* webpackChunkName: "agentRoleList" */ '../views/agent/agentRoleList.vue')
  },
  {
    path: '/agentRoleEdit',
    name: 'agentRoleEdit',
    component: () => import( /* webpackChunkName: "agentRoleEdit" */ '../views/agent/agentRoleEdit.vue')
  },
  {
    path: '/agentInfo',
    name: 'agentInfo',
    component: () => import( /* webpackChunkName: "agentInfo" */ '../views/agent/agentInfo.vue')
  },
  {
    path: '/agenRenewEdit',
    name: 'agenRenewEdit',
    component: () => import( /* webpackChunkName: "agenRenewEdit" */ '../views/agent/agenRenewEdit.vue')
  },
  {
    path: '/enterpriseUserList',
    name: 'enterpriseUserList',
    component: () => import( /* webpackChunkName: "enterpriseUserList" */ '../views/enterprise/enterpriseUserList.vue')
  },
  {
    path: '/enterpriseUserEdit',
    name: 'enterpriseUserEdit',
    component: () => import( /* webpackChunkName: "enterpriseUserEdit" */ '../views/enterprise/enterpriseUserEdit.vue')
  },
  {
    path: '/authenticationInfo',
    name: 'authenticationInfo',
    component: () => import( /* webpackChunkName: "authenticationInfo" */ '../views/enterprise/authenticationInfo.vue')
  },
  {
    path: '/enterpriseOrder',
    name: 'enterpriseOrder',
    component: () => import( /* webpackChunkName: "enterpriseOrder" */ '../views/order/enterpriseOrder.vue')
  },
  {
    path: '/enterpriseOrderEdit',
    name: 'enterpriseOrderEdit',
    component: () => import( /* webpackChunkName: "enterpriseOrderEdit" */ '../views/order/enterpriseOrderEdit.vue')
  },
  {
    path: '/enterpriseAssetList',
    name: 'enterpriseAssetList',
    component: () => import( /* webpackChunkName: "enterpriseAssetList" */ '../views/order/enterpriseAssetList.vue')
  },
  {
    path: '/renewInfo',
    name: 'renewInfo',
    component: () => import( /* webpackChunkName: "enterpriseAssetList" */ '../views/order/renewInfo.vue')
  },
  {
    path: '/relationOrder',
    name: 'relationOrder',
    component: () => import( /* webpackChunkName: "enterpriseAssetList" */ '../views/order/relationOrder.vue')
  },
  {
    path: '/departmentList',
    name: 'departmentList',
    component: () => import( /* webpackChunkName: "departmentList" */ '../views/account/departmentList.vue')
  },
  {
    path: '/departmentEdit',
    name: 'departmentEdit',
    component: () => import( /* webpackChunkName: "departmentEdit" */ '../views/account/departmentEdit.vue')
  },
  {
    path: '/roleList',
    name: 'roleList',
    component: () => import( /* webpackChunkName: "roleList" */ '../views/account/roleList.vue')
  },
  {
    path: '/roleEdit',
    name: 'roleEdit',
    component: () => import( /* webpackChunkName: "roleEdit" */ '../views/account/roleEdit.vue')
  },
  {
    path: '/rolePowerRun',
    name: 'rolePowerRun',
    component: () => import( /* webpackChunkName: "rolePowerRun" */ '../views/account/rolePowerRun.vue')
  },
  {
    path: '/accountList',
    name: 'accountList',
    component: () => import( /* webpackChunkName: "accountList" */ '../views/account/accountList.vue')
  },
  {
    path: '/accountEdit',
    name: 'accountEdit',
    component: () => import( /* webpackChunkName: "accountEdit" */ '../views/account/accountEdit.vue')
  },
  {
    path: '/adminEditPassword',
    name: 'adminEditPassword',
    component: () => import( /* webpackChunkName: "adminEditPassword" */ '../views/account/adminEditPassword.vue')
  },
  {
    path: '/renewRunList',
    name: 'renewRunList',
    component: () => import( /* webpackChunkName: "renewRunList" */ '../views/renew/renewRunList.vue')
  },
  ]
},


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
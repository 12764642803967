<template>

	<div class="application">
		<div class="headTitle">
			<img src="../../assets/imgs/left/logo.png" alt="" />
		</div>
		<el-menu :default-active="activeIndex" unique-opened class="el-menu-vertical-demo" background-color="#1f1f1f"
			text-color="#fff" active-text-color="#ffd04b">
			<el-submenu v-for="(menu, i) in listData" :key="i" :index="i+ ''">
				<template slot="title">
					<img v-if="menu.menu_icon == 'menu'" src="../../assets/imgs/left/menu.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'product'" src="../../assets/imgs/left/product.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'package'" src="../../assets/imgs/left/package.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'agent'" src="../../assets/imgs/left/agent.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'enterprise'" src="../../assets/imgs/left/enterprise.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'order'" src="../../assets/imgs/left/order.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'account'" src="../../assets/imgs/left/account.png" alt=""
						class="left_icon_img">
					<img v-if="menu.menu_icon == 'renew'" src="../../assets/imgs/left/renew.png" alt=""
						class="left_icon_img">
					<span>{{ menu.menu_name }}</span>
				</template>
				<div>
					<el-menu-item-group v-for="(menuTab, index) in menu.second" :key="index">
						<el-menu-item v-if="menuTab.is_show == 1" @click="twoMenuUrlJump(menuTab)" :index="i + '-' + index">{{ menuTab.menu_name }}
						</el-menu-item>
					</el-menu-item-group>
				</div>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
	export default {
		name: "leftMenu",
		data() {
			return {
				listData: [], //菜单列表数组
				activeIndex: '', // 高亮
			};
		},

		created() {},
		mounted() {
			
			// 获取菜单列表
			this.getMenuList();
			
		},
		methods: {
			getMenuList() {
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}menu/mymenu`,
					data: {},
				}).then((res) => {
					if (res.code == "0") {
						this.total = res.count;
						this.listData = res.data;
						
						// 处理一级菜单设置不显示
						this.listData.forEach((item, i) => {
							if(item.is_show == '0'){
								this.listData.splice(i, 1)
							}
						})
						
						// 处理页面刷新时的高亮状态
						this.listData.forEach((item, i) => {
							if(item.second && item.second.length > 0){
								item.second.forEach((child, s) => {
									if (child.frontend_route == this.$route.name) {
										this.activeIndex = `${i}-${s}`
									}
								})
							}
						})
					}
				});
			},

			// 二级菜路由跳转
			twoMenuUrlJump(menuTab) {
				this.$router.push({
					path: `/${menuTab.frontend_route}`,
				});
			},
		},
	};
</script>

<style scoped lang="less">
	// 左侧应用
	.application {
		// padding: 0 6px 20px;
		background: #1f1f1f;
		box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
	}

	.headTitle {
		padding: 19px 20px;

		img {
			width: 170px;
			height: 33px;
		}
	}

	.left_icon_img {
		width: 16px;
		height: 16px;
		margin-right: 6px;
	}
</style>

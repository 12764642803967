<template>
	<div class="saas_admin_page">
		<div class="page_left">
			<leftMenu></leftMenu>
		</div>
		<div class="page_right">
			<heard></heard>

			<div class="page_right_content">
				<router-view />
			</div>
		</div>

		<el-backtop :bottom="130" :right="25"></el-backtop>
	</div>
</template>

<script>
	import leftMenu from "../components/common/leftMenu.vue";
	import heard from "../components/common/heard.vue";
	export default {
		name: "Home",
		components: {
			leftMenu,
			heard,
		},
		created() {},
		methods: {
			getToken() {
				this.$sendHttp({
					method: "post",
					url: `${process.env.VUE_APP_ACCOUNT}api/accesstoken`,
					data: {
						id: 22,
					},
				}).then((res) => {
					console.log(res);
				});
			},
		},
	};
</script>
<style scoped lang="less">
</style>

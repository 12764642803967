<template>
	<div class="head_wrap">
		<header class="head_wrap_content">
			<div class="left">
				<nav>
					<p v-for="(item, index) in headTitle" :key="item.id" :class="index == indexT ? 'p_active': ''">
						{{item.title}}</p>
				</nav>
			</div>
			<div class="right">
				<img src="../../assets/imgs/home/bar8.png" alt="">
				<p>{{user_name}}</p>
				<p class="sign_out" @click="signOutFn">退出</p>
			</div>
		</header>
	</div>
</template>

<script>
	export default {
		name: 'heard',
		data() {
			return {
				indexT: '0', // 高亮
				user_name: '', // 用户名
				headTitle: [{
						id: 1,
						title: 'SaaS运营'
					},
					{
						id: 2,
						title: 'SaaS管理'
					},
				]
			}
		},
		created() {
      setInterval(() => {
      const userInfrom = JSON.parse(window.localStorage.getItem("userInfrom"));
			this.user_name = userInfrom.user_name;
      }, 500);

		},
		methods: {
			// 退出
			signOutFn() {
				this.$confirm('确认退出吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$sendHttp({
						method: 'POST',
						url: `${process.env.VUE_APP_SAAS}adminauth/logout`,
						data: {}
					}).then(res => {
						if (res.code == 0) {
              localStorage.clear()

							this.$message({
								message: '退出成功',
								type: 'success'
							});
              window.location.href = '/login';
							// window.location.href = '/login'
						} else {
							this.$message.error(`${res.msg}`);
						}
					})

				}).catch(() => {});
			}

		}
	}
</script>

<style scoped lang="less">
	.head_wrap {
		height: 48px;
		background-color: #141414;
	}

	.head_wrap_content {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left {
			nav {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				p {
					width: 200px;
					height: 48px;
					line-height: 48px;
					text-align: center;
					color: rgba(255, 255, 255, .7);
					cursor: pointer;
				}

				.p_active {
					color: rgba(255, 255, 255, 1);
					background-color: #EE7B1D;
				}
			}

		}

		.right {
			margin-right: 66px;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			img {
				width: 24px;
				height: 24px;
			}

			p {
				font-size: 14px;
				color: rgba(255, 255, 255, 0.85);
				margin-left: 8px;
			}

			.sign_out {
				margin-left: 16px;
				cursor: pointer;
			}

		}
	}
</style>
